import { type ComputedDatum, type DatumId } from "@nivo/pie";
import { capitalize } from "lodash";

import { DataVizTooltip } from "./dataviz-tooltip";

import type { DataVizBaseDatum } from "../../types";

/**
 * Extracts datum color from a ComputedDatum object for use in Nivo Chart components
 *
 * Example Usage:
 * <ResponsivePie colors={extractDatumColor} data={props.data} />
 */
export const extractDatumColor = (
  datum: Omit<ComputedDatum<DataVizBaseDatum>, "fill" | "color" | "arc">,
) => {
  return datum.data.color;
};

/**
 * Datum enrichment and renderer for the DataVizTooltip component for use in Nivo Chart components
 *
 * Example Usage:
 * <ResponsivePie tooltip={renderDataVizTooltip} />
 */
export const renderDataVizTooltip = (
  datum: ComputedDatum<DataVizBaseDatum & { label?: DatumId }>,
) => {
  const enrichedDatum = {
    ...datum,
    detail: datum.data?.detail || "",
    formattedValue: datum.data.value.toLocaleString(),
    label: capitalize(String(datum.data?.label)) ?? "",
  };
  return <DataVizTooltip datum={enrichedDatum} />;
};
