import {
  EuiBadge,
  EuiFlexGroup,
  EuiFlexItem,
  EuiText,
} from "@equipmentshare/ds2";
import { DatumId } from "@nivo/pie";
import React from "react";
import "./index.css";

export type DataVizTooltipDatum = {
  color: string;
  label: DatumId;
  value: number;
  detail?: React.ReactNode;
  formattedValue?: string;
};

export type DataVizTooltipProps = {
  datum: DataVizTooltipDatum;
  hasBorder?: boolean;
  label?: string;
};

export const DataVizTooltip = (props: DataVizTooltipProps) => {
  return (
    <div
      className="dataviz-tooltip-container"
      data-testid="dataviz-tooltip-container"
    >
      <EuiFlexGroup
        className="dataviz-tooltip"
        data-testid="dataviz-tooltip"
        direction="column"
        justifyContent="spaceBetween"
        style={{
          borderColor: props.hasBorder ? `${props.datum.color}` : "transparent",
        }}
      >
        {props.label && (
          <EuiFlexItem className="label" data-testid="dataviz-tooltip-label">
            <EuiText color="subdued" size="xs">
              <p>{props.label}</p>
            </EuiText>
          </EuiFlexItem>
        )}
        <EuiFlexGroup
          alignItems="flexStart"
          direction="row"
          justifyContent="spaceBetween"
          responsive={false}
        >
          <EuiFlexGroup
            alignItems="center"
            direction="row"
            gutterSize="s"
            responsive={false}
          >
            <EuiFlexItem grow={false}>
              <EuiBadge
                color={props.datum.color}
                style={{
                  border: "none",
                  padding: "0",
                  width: "10px",
                  height: "10px",
                }}
              />
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiText color="default" size="xs">
                <p>
                  <strong>{props.datum.label}:</strong>
                </p>
              </EuiText>
            </EuiFlexItem>
          </EuiFlexGroup>
          <EuiFlexItem data-testid="dataviz-tooltip-value">
            <EuiText color="default" size="xs" style={{ marginLeft: "auto" }}>
              <p>
                <strong>
                  {props.datum.formattedValue ?? props.datum.value}
                </strong>
              </p>
            </EuiText>
          </EuiFlexItem>
        </EuiFlexGroup>
        {props.datum.detail && (
          <EuiFlexItem className="detail" data-testid="dataviz-tooltip-detail">
            <EuiText color="subdued" size="xs">
              {props.datum.detail}
            </EuiText>
          </EuiFlexItem>
        )}
      </EuiFlexGroup>
    </div>
  );
};
