import { ArcLabelsProps, DatumWithArcAndColor } from "@nivo/arcs";
import { ResponsivePie } from "@nivo/pie";

import { DataVizContainerDimensionsDefaults } from "../../../types";
import { ResponsivePieWrapper } from "../responsive-pie-wrapper";

export type SliceDatum = {
  color: string;
  name: string;
  value: number | string;
};

export type PieDatum = {
  color: string;
  id: SliceDatum["name"];
  label: SliceDatum["name"];
  value: SliceDatum["value"];
};

export type ArcLabel = {
  color?: ArcLabelsProps<DatumWithArcAndColor>["arcLabelsTextColor"];
  showLabels?: boolean;
  skipAngle?: number;
};

export type PieModifiers = {
  arcLabelRenderer?: (labelValue: number | string) => string;
};

export type PieProps = {
  arcLabels?: ArcLabel;
  colors?: string[];
  containerDimensions?: DataVizContainerDimensionsDefaults;
  data: PieDatum[];
  modifiers?: PieModifiers;
  testId?: string;
};

export const Pie = (props: PieProps) => {
  const arcLabelRenderer = props?.modifiers?.arcLabelRenderer
    ? props.modifiers.arcLabelRenderer
    : (e: string | number) => String(e);
  const { testId = "responsive-pie" } = props;

  return (
    <ResponsivePieWrapper {...props.containerDimensions} svgTestId={testId}>
      <ResponsivePie
        activeOuterRadiusOffset={8}
        arcLabel={(datum) => arcLabelRenderer(datum.value)}
        arcLabelsRadiusOffset={0.75}
        arcLabelsSkipAngle={props?.arcLabels?.skipAngle}
        arcLabelsTextColor={props?.arcLabels?.color}
        colors={props.colors ? props.colors : (datum) => datum.data.color}
        data={props.data}
        enableArcLabels={props?.arcLabels?.showLabels || false}
        enableArcLinkLabels={false}
        innerRadius={0}
        margin={{ top: 16, right: 16, bottom: 16, left: 16 }}
        motionConfig="default"
        transitionMode="startAngle"
      />
    </ResponsivePieWrapper>
  );
};
